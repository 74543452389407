<template>
  <v-container>
    <v-row>
      <v-col>
        <s-crud
          edit
          :filter="filter"
          searchInput
          :config="config"
          add
          remove
          title="Configuración Solicitudes"
          @save="save($event)"
        >
          <template scope="props">
            <v-container>
              <v-row justify="center">
                <v-col cols="6" lg="3" class="s-col-form">
                  <s-select-area
                    label="Área"
                    :text="props.item.AreName"
                    v-model="props.item.AreID"
                    full
                  />
                </v-col>
                <v-col cols="6" lg="7" class="s-col-form">
                  <s-text
                    label="Tipos de Solicitudes"
                    v-model="props.item.text"
                  ></s-text>
                </v-col>
              </v-row>
              <v-row justify="center">
                  <v-col cols="6" lg="6" class="s-col-form">
                  <s-text
                    label="Observaciones"
                    v-model="props.item.text"
                  ></s-text>
                </v-col>
                <v-col lg="2" cols="2" class="s-col-form">
                  <s-switch label="Circuito" v-model="props.item.LcsCircuit" />
                </v-col>
                <v-col cols="4" lg="2" class="s-col-form">
                  <s-select-definition
                    :def="1179"
                    label="Estado"
                    v-model="props.item.LcsStatus"
                  />
                </v-col>
                
              </v-row>
               <v-row justify="center" v-if="props.item.LcsCircuit">
                <v-col lg="2" cols="4" class="s-col-form">
                  <s-switch label="Circuito x Área" v-model="props.item.LcsStandard" />
                </v-col>
                 <v-col lg="8" cols="8" class="s-col-form" v-if="!props.item.LcsStandard">                
                  <s-circuit label="Circuito Asociado" ></s-circuit>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:ExtStatus="{ row }">
            <v-chip x-small :color="row.ExtStatus == 1 ? 'success' : 'error'">{{
              row.ExtStatus == 1 ? "Activo" : "Baja"
            }}</v-chip>
          </template>
        </s-crud>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _service from "@/services/InformationTechnology/LicenseService";
import SCircuit from "@/views/InformationTechnology/Configuration/Circuit/CircuitView";
export default {
  components: {SCircuit},

  data() {
    return {
      filter: {},
      config: {
        model: {
          LcsID: "ID",
          LcsDateBegin: "date",
          LcsDateEnd: "date",
          LcsStatus: "",
        },
        headers: [
          { text: "Estado", value: "LcsStatus", width: 50 },
          { text: "Área", value: "AreName" },
          { text: "Solicitudes", value: "Text" },
          { text: "Consideración", value: "LcsStandart", width: 350 },
        ],
        service: _service,
      },
      items: [],
      license: {},
    };
  },
  created() {},
  methods: {
    save(item) {
      alert("emitiendo");
      this.license = item;
      this.$refs.childAcquisition.save(); //evento del componente
    },
  },
};
</script>
